




















































import { defineComponent, PropType } from '@vue/composition-api'
import { VueSrcData } from '@/inc/directives/src'

interface Signatories {
  picture?: VueSrcData
  name: string
  jobTitle?: string
  company?: string
  type?: string
  country: string
  url: string
  website: string
}

export default defineComponent({
  props: {
    content: Array as PropType<Signatories[]>,
    isIndividual: Boolean,
  },
})
