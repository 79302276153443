
































import { defineComponent } from '@vue/composition-api'
import { push, GtmFollow } from '@/inc/utils'

export default defineComponent({
  name: 'g-socials',
  props: {
    content: Object,
    redux: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, ctx) {
    const onClick = slug => {
      if (ctx.root.$router.$$type === 'client') {
        const layer: GtmFollow = {
          event: 'social-follow',
          eventCategory: 'social',
          eventAction: 'follow',
          eventLabel: slug,
        }

        // DGTM 5.2.5
        push(layer)
      }
    }

    return {
      onClick,
    }
  },
})
