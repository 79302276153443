












































































































import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'
import { mapActions } from '@/store/utils'

export default defineComponent({
  props: {
    content: Object,
  },
  setup(_props, ctx) {
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const text = ref<null | HTMLElement>(null)

    const { addPopupContent } = mapActions(ctx, 'popup', ['addPopupContent'])
    const openPopup = obj => {
      addPopupContent(obj)
    }

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const textAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      text,
      textAnims,
      isOpen,
      toggle,
      vBar,
      openPopup,
    }
  },
})
