/* eslint-disable quote-props, @typescript-eslint/no-explicit-any */
export interface GtmVirtualPage {
  event: 'virtualPage'
  page: {
    language: 'en'
    category: string
  }
}

interface GtmContentPageContent {
  title?: string
  'content-topic'?: string
  'content-type'?: string
  'content-public'?: number
  'content-lead'?: number
}
export interface GtmContentPage {
  event: 'contentPage'
  page: {
    language: 'en'
    category: string
  }
  content: GtmContentPageContent
}

export interface GtmSearch {
  event: 'search' | 'virtualPage'
  page: {
    language: 'en'
    category: string
  }
  search?: {
    keywords: string | undefined
    'search-type': string
    'search-topic': string
    'search-region': string
    'search-flag': number
  }
}

export interface GtmSearchClick {
  event: 'search-result-click'
  eventCategory: 'search-result'
  eventAction: 'click'
  eventLabel: string
  search: {
    keywords: string | undefined
    'search-type': string | undefined
    'search-topic': string | undefined
    'search-region': string | undefined
    'search-flag': 0 | 1
  }
}

export interface GtmMemberForm {
  event: 'lead-form'
  eventCategory: 'download-form'
  eventAction: 'click'
  eventLabel: 'become-member'
}

export interface GtmDownload {
  event: 'download'
  eventCategory: 'download'
  eventAction: 'click'
  eventLabel: string
  content: GtmContentPageContent
}

export interface GtmJobOffer {
  event: 'job-offer'
  eventCategory: 'job-offer'
  eventAction: 'download' | 'contact'
  eventLabel: string
}

export interface GtmShare {
  event: 'social-share'
  eventCategory: 'social'
  eventAction: 'share'
  eventLabel: string
  'shared-url': string
}

export interface GtmFollow {
  event: 'social-follow'
  eventCategory: 'social'
  eventAction: 'follow'
  eventLabel: string
}

export interface GtmRegister {
  event: 'outbound-link'
  eventCategory: 'outbound-link'
  eventAction: string
  eventLabel: string
}

export interface GtmContact {
  event: 'contact'
  eventCategory: 'contact'
  eventAction: 'click'
  eventLabel: string
}

export interface GtmMyUITP {
  event: 'myuitp-login'
  eventCategory: 'myuitp'
  eventAction: 'click'
  eventLabel: 'login'
}

type GtmEvent =
  | GtmVirtualPage
  | GtmContentPage
  | GtmSearch
  | GtmSearchClick
  | GtmDownload
  | GtmJobOffer
  | GtmShare
  | GtmFollow
  | GtmRegister
  | GtmContact
  | GtmMyUITP
  | GtmMemberForm

const getGtmCategory = (template: string): string => {
  const arr = {
    region: 'worlwide',
    page: 'others',
    event: 'events-training',
    topic: 'topics',
    news: 'resources',
    publication: 'resources',
    project: 'resources',
    campaign: 'resources',
  } as any

  return arr[template] ? (arr[template] as string) : template
}

export const push = (layer: GtmEvent) => {
  if (window.dataLayer && process.env.VUE_APP_ENV === 'production') {
    window.dataLayer.push(layer)
  } else {
    console.table(layer)
  }
}

export const getVirtualPage = (template: string): GtmVirtualPage => ({
  event: 'virtualPage',
  page: {
    language: 'en',
    category: getGtmCategory(template) as string,
  },
})

export const getContentPage = (
  template: string,
  content: GtmContentPageContent
): GtmContentPage => {
  const defaultContent: GtmContentPageContent = {
    title: '',
    'content-topic': '',
    'content-type': '',
    'content-public': 0,
    'content-lead': 0,
  }

  return {
    event: 'contentPage',
    page: {
      language: 'en',
      category: getGtmCategory(template),
    },
    content: {
      ...defaultContent,
      ...content,
    },
  }
}

export const contentTemplates = [
  'search-tool',
  'topic',
  'news',
  'publication',
  'project',
  'campaign',
  'event',
  'training',
  'community',
  'visibility-opportunities',
]
