<template>
  <div class="ctas" :class="{ two: content.length === 2, redux: redux }">
    <div class="ctas-inner h-wrapper">
      <g-cta
        class="ctas__cta"
        v-for="(cta, index) in content"
        :key="`cta-${index}`"
        :content="cta"
        :dark="index > 0"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ctas {
  @include default-margin;

  position: relative;
  overflow: hidden;
  background-color: $c-green-light;

  &.redux {
    background-color: transparent;
  }

  .contact + &,
  .expertise + &,
  .about-offices + &,
  .card-grid + &,
  .flexible.last-no-margin-bottom + & {
    margin-top: 0;
  }
}

.ctas-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .ctas.two &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 50vw;
    height: 100%;
    background-color: $c-green-dark;
  }
}

.ctas__cta {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;

  & + & {
    background-color: $c-green-dark;
  }

  @include mq(xl) {
    width: 50%;
  }
}
</style>

<style lang="scss">
.cta-inner {
  .ctas.two & {
    @include mq(xl) {
      width: col(6, 8);
      margin-left: col(1, 8);
    }
  }
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Array,
    redux: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
