// "Dynamically" load `support/modern` or `support/legacy`
import polyfills from '@/inc/polyfills'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export default function client(_ctx: any): void {
  console.log('CLIENT')

  const initApp = (): void => {
    polyfills.init()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const domReady = (): Promise<any> =>
    new Promise(resolve => {
      if (
        document.readyState === 'complete' ||
        document.readyState !== 'loading'
      ) {
        resolve()
      } else {
        document.addEventListener('DOMContentLoaded', resolve)
      }
    })

  Promise.all([domReady()]).then(initApp)
}
