




















































import { defineComponent } from '@vue/composition-api'
import GallerySlideshow from '@/components/g/GallerySlideshow.vue'
import GalleryGrid from '@/components/g/GalleryGrid.vue'

export default defineComponent({
  props: {
    content: Object,
  },
  components: {
    GallerySlideshow,
    GalleryGrid,
  },
})
