var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('section',{directives:[{name:"aware",rawName:"v-aware",value:({
    appear: {
      once: true,
      threshold: [0.5, 0.75, 1],
    },
  }),expression:"{\n    appear: {\n      once: true,\n      threshold: [0.5, 0.75, 1],\n    },\n  }"}],staticClass:"highlight",on:{"appear":_vm.onAppear}},[(_vm.content.title)?_c('h2',{staticClass:"h-wrapper highlight__title h1"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]):_vm._e(),_c('div',{staticClass:"highlight-wrapper"},[_c('div',{staticClass:"highlight__content h-wrapper"},[(_vm.hasSlides)?_c('div',{ref:"progressRef",staticClass:"hightlight__picture__progress"}):_vm._e(),_c('transition',{attrs:{"css":false,"mode":'out-in'},on:{"enter":_vm.slideEnter,"leave":_vm.slideLeave}},[_c('div',{key:_vm.currentSlideIndex,staticClass:"highlight-inner"},[_c('div',{ref:"pictureContainerRef",staticClass:"highlight__picture-outer"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.picture),expression:"picture"}],ref:"pictureRef",staticClass:"highlight__picture",attrs:{"alt":'',"data-animate":"mask"}})]),_c('div',{ref:"wrapperRef",staticClass:"highlight__main",class:{
              'is-slideshow': _vm.hasSlides,
            }},[_c('div',{staticClass:"highlight__slideshow",style:({
                '--offset': _vm.currentSlideIndex,
              })},_vm._l((_vm.content.items),function(item,i){return _c('div',{key:i,staticClass:"highlight__slideshow__slide",class:{
                  active: i === _vm.currentSlideIndex,
                },attrs:{"data-index":i}},[_c('div',{ref:"headlineRef",refInFor:true,staticClass:"highlight__main__headline",attrs:{"data-animate":"fade"}},[(item.date)?_c('span',{staticClass:"highlight__main__date"},[_vm._v(_vm._s(item.date))]):_vm._e()]),_c('h3',{ref:"titleRef",refInFor:true,staticClass:"highlight__main__title",attrs:{"data-animate":"fade"}},[_vm._v(" "+_vm._s(item.title)+" ")]),(!_vm.hasSlides)?_c('div',{ref:"textRef",refInFor:true,staticClass:"highlight__main__text",attrs:{"data-animate":"fade"},domProps:{"innerHTML":_vm._s(item.text)}}):_vm._e(),(item.link)?_c('div',{ref:"linkRef",refInFor:true,staticClass:"cta__link-container",attrs:{"data-animate":"fade"}},[_c('g-link',{staticClass:"cta__link white",attrs:{"content":item.link}})],1):_vm._e()])}),0),(_vm.hasSlides)?_c('div',{staticClass:"highlight__controls"},[_c('div',{staticClass:"highlight__controls__prevnext"},[_c('SlideshowBtn',{ref:"prevBtnRef",staticClass:"prev",nativeOn:{"click":function($event){return _vm.prev($event)}}}),_c('SlideshowBtn',{ref:"nextBtnRef",staticClass:"next dark",nativeOn:{"click":function($event){return _vm.next($event)}}})],1)]):_vm._e()])])]),(_vm.hasSlides)?_c('div',{ref:"counterRef",staticClass:"highlight__counter"},[_c('div',{staticClass:"highlight__counter__current"},[_vm._v(" "+_vm._s(_vm.currentSlideIndex + 1)+" ")]),_c('div',{staticClass:"highlight__counter__separator"},[_vm._v("/")]),_c('div',{staticClass:"highlight__counter__total"},[_vm._v(" "+_vm._s(_vm.content.items.length)+" ")])]):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }