






















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { GenericObject } from '@/inc/types'

interface FlexibleObject {
  component: string
  content: GenericObject
}

export default defineComponent({
  props: {
    content: {
      type: Array as PropType<FlexibleObject[]>,
      default: () => [],
    },
  },
  setup(props) {
    const availableFlexibles = {
      duo: 'g-duo',
      embeds: 'g-embeds',
      collapse: 'g-flexible-collapse',
      contact: 'g-contact',
      ctas: 'g-ctas',
      gallery: 'g-gallery',
      keyfacts: 'g-keyfacts',
      keypoints: 'g-keypoints',
      comparison: 'g-packages',
      quote: 'g-quote',
      socials: 'g-socials',
      videos: 'g-video',
      highlights: 'g-highlight-slideshow',
      corporateSignatories: 'g-corporate-signatories',
      individualSignatories: 'g-individual-signatories',
      accessibilitySignatories: 'g-accessibility-signatories',
    }
    const flexibles = computed(() => {
      const keys = Object.keys(availableFlexibles)

      return props.content.filter(f => keys.indexOf(f.component) !== -1)
    })

    const lastNoMarginBottom = computed(() => {
      if (!props.content || props.content.length === 0) {
        return false
      }

      const { component } = props.content[props.content.length - 1]

      return ['contact'].includes(component)
    })

    return {
      availableFlexibles,
      flexibles,
      lastNoMarginBottom,
    }
  },
})
