


























































































































































// Footer.vue.ts
import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import footerLegal from '@/components/chrome/footer/Legal.vue'
import { push, GtmMyUITP, isExternalURL } from '@/inc/utils'

export default defineComponent({
  components: {
    footerLegal,
  },
  setup(props, ctx) {
    const onClickLink = e => {
      if (
        ctx.root.$router.$$type === 'client' &&
        e.target.href.includes('my.uitp.org')
      ) {
        const layer: GtmMyUITP = {
          event: 'myuitp-login',
          eventCategory: 'myuitp',
          eventAction: 'click',
          eventLabel: 'login',
        }

        // DGTM 5.2.10
        push(layer)
      }
    }

    return {
      ...mapGetters(ctx, ['chrome']),
      isExternalURL,
      onClickLink,
    }
  },
})
