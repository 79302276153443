









































import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Object,
    onChange: {
      type: Function,
      default: (n, v) => {
        console.log(n, v)
      },
    },
  },
  setup(props) {
    const isOpen = ref(false)
    let label = props.content.label ? props.content.label : props.content.name
    const current = ref<string>(
      label.replace(/^\w/, c => c.toUpperCase())
    )

    const close = () => {
      isOpen.value = false
    }

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const select = (name, value, label) => {
      props.onChange(name, value)
      current.value = label
      close()
    }

    const clear = () => {
      let label = props.content.label ? props.content.label : props.content.name
      current.value = label.replace(/^\w/, c => c.toUpperCase())
      props.onChange(label.toLowerCase(), '')
      isOpen.value = false
    }

    const isSelected = computed(
      () => {
        let label = props.content.label ? props.content.label : props.content.name
        current.value !==
        label.replace(/^\w/, c => c.toUpperCase())
      }
    )

    return {
      clear,
      current,
      close,
      toggle,
      isOpen,
      isSelected,
      select,
    }
  },
})
