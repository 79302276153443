import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import {
  GenericObject,
  Searchtool,
  SearchtoolState,
  RootState,
} from '@/inc/types'

function getQuery(state: SearchtoolState) {
  const { types, topics, regions } = state.data.filters

  const obj = {
    types: '',
    topics: '',
    regions: '',
    tags: '',
    all: '',
  }
  const typesArr: Array<string> = []

  Object.keys(types || {}).forEach((k: string) => {
    types[k].checked && typesArr.push(types[k].slug)
  })

  if (typesArr.length > 0) {
    obj.types = typesArr.join(',')
  }

  const topicsArr: Array<string> = []

  Object.keys(topics || {}).forEach((k: string) => {
    topics[k].checked && topicsArr.push(topics[k].slug)
  })

  if (topicsArr.length > 0) {
    obj.topics = topicsArr.join(',')
  }

  const regionsArr: Array<string> = []

  Object.keys(regions || {}).forEach((k: string) => {
    regions[k].checked && regionsArr.push(regions[k].slug)
  })

  if (regionsArr.length > 0) {
    obj.regions = regionsArr.join(',')
  }

  const tagsArr: Array<string> = []

  state.data.tags.selected.forEach((t: string) => {
    tagsArr.push(encodeURIComponent(t))
  })

  if (tagsArr.length > 0) {
    obj.tags = tagsArr.join(',')
  }

  if (state.data.all.length > 0) {
    obj.all = state.data.all.join(',')
  }

  return obj
}

const state: SearchtoolState = {
  data: {
    all: [],
    filters: {
      types: {},
      topics: {},
      regions: {},
    },
    tags: {
      selected: [],
      suggested: [],
    },
  },
}

const getters: GetterTree<SearchtoolState, RootState> = {
  query: state => getQuery(state),
  tags: state => state.data.tags,
  filters: state => state.data.filters,
}

const mutations: MutationTree<SearchtoolState> = {
  SET_SEARCHTOOL(state, payload: Searchtool) {
    if (payload) {
      Object.assign(state.data, payload)
    }
  },
  ADD_TAGS(state, payload: string[]) {
    if (payload) {
      const { selected } = state.data.tags

      payload.forEach(t => {
        const stripped = t.replace(/^\s*|\s*$/g, '')

        selected.push(stripped)
      })
    }
  },
  REMOVE_TAG(state, payload: string) {
    if (payload) {
      const i = state.data.tags.selected.indexOf(payload)

      i !== -1 && state.data.tags.selected.splice(i, 1)
    }
  },
  CHANGE_FILTER(state, payload: GenericObject) {
    const { name, group, checked } = payload

    state.data.filters[group][name].checked = checked
  },
  CLEAR_FILTER(state, payload: string) {
    Object.keys(state.data.filters[payload]).forEach(k => {
      if (state.data.filters[payload][k].checked) {
        state.data.filters[payload][k].checked = false
      }
    })
  },
  VIEW_ALL(state, payload: string) {
    state.data.all.push(payload)
  },
  CLEAR_ALL(state) {
    state.data.all = []
    state.data.tags.selected = []

    Object.keys(state.data.filters).forEach(j => {
      Object.keys(state.data.filters[j]).forEach(k => {
        if (state.data.filters[j][k].checked) {
          state.data.filters[j][k].checked = false
        }
      })
    })
  },
}

const actions: ActionTree<SearchtoolState, RootState> = {
  initSearchtool({ commit }, payload) {
    commit('SET_SEARCHTOOL', payload)
  },
}

export const searchtool: Module<SearchtoolState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
