import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Pictures, PicturesState, RootState } from '@/inc/types'

const state: PicturesState = {
  data: {
    pictures: null,
  } as Pictures,
}

const getters: GetterTree<PicturesState, RootState> = {
  pictures: state => state.data,
}

const mutations: MutationTree<PicturesState> = {
  PICTURES_ADD(state, payload: Pictures) {
    Object.assign(state.data, payload)
  },
  PICTURES_REMOVE(state) {
    state.data.pictures = null
  },
}

const actions: ActionTree<PicturesState, RootState> = {
  addPictures({ commit }, payload) {
    commit('PICTURES_ADD', payload)
  },
  removePictures({ commit }, payload) {
    commit('PICTURES_REMOVE', payload)
  },
}

export const pictures: Module<PicturesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
