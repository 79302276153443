















































































































































































































import { defineComponent } from '@vue/composition-api'
import { isExternalURL } from '@/inc/utils'

export default defineComponent({
  name: 'g-link',
  props: {
    content: Object,
  },
  setup() {
    return {
      isExternalURL,
    }
  },
})
