























































































































































































































































































































































































































































































































































// Menu.vue.ts
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import { push, GtmMyUITP, isExternalURL, toKebab } from '@/inc/utils'
import {
  Nav,
  TmpNav,
  NavChild,
  NavItem,
  Link,
  Menu,
  SpecificObject,
} from '@/inc/types'
import NavSub from './NavSub.vue'

export default defineComponent({
  setup(props, ctx) {
    const worldwide = {
      children: props?.content?.worlwide,
    }
    const { chrome } = mapGetters(ctx, ['chrome'])
    const nav: Nav = (chrome.value.main as TmpNav).map(navItem => {
      const { label, children: tmpChildren } = navItem
      const tmp: SpecificObject<Menu> = {}

      if (tmpChildren) {
        tmpChildren.forEach(tmpChild => {
          const { label, url, type } = tmpChild
          const link: Link = {
            label,
            url,
          }
          const topLabel = type || 'default'

          if (tmp[topLabel]) {
            tmp[topLabel].push(link)
          } else {
            tmp[topLabel] = [link]
          }
        })
      }

      const children: NavChild[] = []

      Object.keys(tmp).forEach(label => {
        children.push({
          label,
          items: tmp[label],
        })
      })

      return {
        label,
        children,
      } as NavItem
    })

    const isSubMenuOpen = ref<boolean>(false)
    const currentSubMenu = ref<string>('client')
    const cta = ref<null | HTMLElement>(null)
    const isOpen = ref<boolean>(false)
    const subItems = ref<HTMLElement[]>([])
    const trigger = ref<HTMLElement>()
    const toggleMenu = () => {
      isOpen.value = !isOpen.value

      const navWrapper = document.querySelector('.nav') as HTMLElement
      const bodyElement = document.querySelector('body') as HTMLBodyElement
      const navMenu = document.querySelector('.nav-menu') as HTMLElement
      const universeSwitcher = document.querySelector(
        '.nav-menu__universe-switcher'
      ) as HTMLElement

      bodyElement.classList.toggle('is-locked')
      navMenu.classList.toggle('is-hidden')
      universeSwitcher.classList.toggle('is-hidden')
      navWrapper.classList.toggle('is-mega-open')
    }

    const closeMenu = () => {
      isOpen.value = false

      const navWrapper = document.querySelector('.nav') as HTMLElement
      const bodyElement = document.querySelector('body') as HTMLBodyElement
      const navMenu = document.querySelector('.nav-menu') as HTMLElement
      const universeSwitcher = document.querySelector(
        '.nav-menu__universe-switcher'
      ) as HTMLElement

      bodyElement.classList.remove('is-locked')
      navMenu.classList.remove('is-hidden')
      universeSwitcher.classList.remove('is-hidden')
      navWrapper.classList.toggle('is-mega-open')
    }

    const openSubMenu = () => {
      isSubMenuOpen.value = true
    }
    const closeSubMenu = () => {
      isSubMenuOpen.value = false
    }
    const isActiveMenu = (menu: string) => currentSubMenu.value === menu
    const setActiveMenu = (sub: string) => {
      currentSubMenu.value = sub

      openSubMenu()
    }

    const goTo = () => {
      closeMenu()

      const ctaUrl = cta?.value?.dataset.url

      window.location.href = window.location.origin + ctaUrl
    }

    const onClickLink = e => {
      if (ctx.root.$router.$$type === 'client') {
        if (e.target.href.includes('my.uitp.org')) {
          const layer: GtmMyUITP = {
            event: 'myuitp-login',
            eventCategory: 'myuitp',
            eventAction: 'click',
            eventLabel: 'login',
          }

          // DGTM 5.2.10
          push(layer)
        }
      }
    }

    onMounted(() => {
      ctx.root.$ee.$on('close-menu', closeMenu)

      if (window.innerWidth > 800) {
        setActiveMenu('topics')
      }
    })

    return {
      ...mapGetters(ctx, ['chrome']),
      closeMenu,
      cta,
      closeSubMenu,
      currentSubMenu,
      goTo,
      isActiveMenu,
      isExternalURL,
      isOpen,
      isSubMenuOpen,
      nav,
      onClickLink,
      openSubMenu,
      setActiveMenu,
      subItems,
      toggleMenu,
      toKebab,
      trigger,
      worldwide,
    }
  },

  props: {
    content: Object,
  },

  components: {
    NavSub,
  },
})
