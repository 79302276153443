var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-address h-spacer-m"},[_c('div',{staticClass:"contact-address__title-outer"},[_c('h2',{staticClass:"contact-address__title"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")])]),_c('div',{staticClass:"contact-address-inner"},[_c('div',{staticClass:"contact-address__headline"},[_vm._v(" Information ")]),(_vm.content.location)?_c('div',{staticClass:"contact-address__office"},[_vm._v(" "+_vm._s(_vm.content.location)+" ")]):_vm._e(),(_vm.content.address)?_c('contact-item',{staticClass:"contact-address__item",attrs:{"content":{
        text: _vm.content.address,
        icon: 'pin',
      }}}):_vm._e(),(_vm.content.email)?_c('contact-item',{staticClass:"contact-address__item",attrs:{"content":{
        headline: 'Email',
        link: _vm.content.email,
        icon: 'email',
      }}}):_vm._e(),(_vm.content.phone)?_c('contact-item',{staticClass:"contact-address__item",attrs:{"content":{
        headline: 'Telephone',
        link: _vm.content.phone,
      }}}):_vm._e(),(_vm.content.fax)?_c('contact-item',{staticClass:"contact-address__item",attrs:{"content":{
        headline: 'Fax',
        text: _vm.content.fax,
      }}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }