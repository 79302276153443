










































































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import SlideshowBtn from '@/components/SlideshowBtn.vue'

interface Slide {
  caption?: string
}

export default defineComponent({
  props: {
    slides: Array as () => Slide[],
  },
  components: { SlideshowBtn },
  setup() {
    let flickity
    const galleryRef = ref<HTMLElement>()
    const currentSlideIndex = ref(0)

    onMounted(async () => {
      if (galleryRef.value) {
        const Flickity = await import(
          /* webpackChunkName: 'vendor-flickity' */ 'flickity'
        ).then(module => module.default)

        flickity = new Flickity(galleryRef.value as HTMLElement, {
          cellSelector: '.gallery__item',
          pageDots: false,
          prevNextButtons: false,
          cellAlign: 'center',
          wrapAround: true,
        })

        flickity.on('change', () => {
          currentSlideIndex.value = flickity.selectedIndex
        })
      }
    })

    onUnmounted(() => {
      if (flickity) {
        flickity.destroy()
      }
    })

    const prev = () => {
      if (flickity) {
        flickity.previous(true, false)
      }
    }

    const next = () => {
      if (flickity) {
        flickity.next(true, false)
      }
    }

    const captionEnter = (el, done) => {
      gsap.killTweensOf(el)
      gsap.from(el, {
        opacity: 0,
        y: 5,
        duration: 0.25,
        onComplete: done,
      })
    }

    const captionLeave = (el, done) => {
      gsap.killTweensOf(el)
      gsap.to(el, {
        opacity: 0,
        y: -5,
        duration: 0.25,
        onComplete: done,
      })
    }

    return {
      galleryRef,
      prev,
      next,
      currentSlideIndex,
      captionEnter,
      captionLeave,
    }
  },
})
