
























































import { defineComponent } from '@vue/composition-api'
import { parseRoute } from '@/inc/utils'

export default defineComponent({
  name: 'not-found',
  setup(props, ctx) {
    const { api, endpoint, resource } = parseRoute(ctx.root.$route)
    const emoji = '¯\\_(ツ)_/¯'

    return {
      url: `${api}/${endpoint}/${resource}`,
      emoji,
    }
  },
})
