// Webpack imports
import '@/styles/main.scss'

import Vue from 'vue'

import AppComponent from '@/App.vue'
import ErrorComponent from '@/Error.vue'
import { App, AppType } from '@/inc/types'
import { pascalToKebab } from '@/inc/utils'
import createRouter from '@/router'
import createStore from '@/store'

console.info('ENV', process.env)
Vue.config.productionTip = false

// Event Emitter
const ee = new Vue()

// eslint-disable-next-line no-prototype-builtins
if (!Vue.prototype.hasOwnProperty('$ee')) {
  Object.defineProperties(Vue.prototype, {
    $ee: {
      get() {
        return ee
      },
    },
  })
}

import ReadMore from '@/components/shortcode/ReadMore.vue'
import Link from '@/components/shortcode/Link.vue'

Vue.component('sc-read-more', ReadMore)
Vue.component('sc-link', Link)

// Import all global components
// Btn.vue => <g-btn></g-btn>
const requireComponent = require.context('@/components/g', true, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  let baseComponentConfig = requireComponent(fileName)

  baseComponentConfig = baseComponentConfig.default || baseComponentConfig

  const baseComponentName =
    baseComponentConfig.name ||
    `g-${pascalToKebab(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))}`

  Vue.component(baseComponentName, baseComponentConfig)
})

// Directives
import VueOutside from '@/inc/directives/outside'
Vue.directive('outside', VueOutside)
import VueSrc from '@/inc/directives/src'
Vue.directive('src', VueSrc)

// Plugins
import VueAware from 'vue-aware'
Vue.use(VueAware)
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)
import VueSvgSprite from 'vue-svg-sprite'
Vue.use(VueSvgSprite, { url: '' })

// Filters
import LineBreak from '@/inc/filters/linebreak'
Vue.filter('linebreak', LineBreak)
import Modifiers from '@/inc/filters/modifiers'
Vue.filter('modifiers', Modifiers)

import i18n from '@/inc/i18n'

// Export factory function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApp(ctx: any): App {
  const { type } = ctx as { type: AppType }

  console.info('MAIN:createApp', type)

  const store = createStore()
  const router = createRouter()
  const app = {
    ErrorComponent,
    i18n,
    router,
    store,
    // This is necessary, it is for vue-meta
    head: {
      titleTemplate: '%s',
    },
    // This will expose `$root.$options.$resource` into components (server-side)
    $resource: ctx.$resource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(AppComponent),
  }

  return app
}
