






















































































































































import { defineComponent /* Ref*/ } from '@vue/composition-api'
// Import { gsap } from 'gsap'

export default defineComponent({
  props: {
    content: Object,
  },

  // Setup() {
  //   const headline = ref<HTMLElement | null>(null)
  //   const baseline = ref<HTMLElement | null>(null)
  //   const title = ref<HTMLElement | null>(null)
  //   const text = ref<HTMLElement | null>(null)

  //   const onAppear = isInViewport => {
  //     const tl = gsap.timeline()
  //     tl.set(headline.value, {
  //       opacity: 0,
  //       y: 20,
  //     })
  //       .set(title.value, {
  //         opacity: 0,
  //         y: 20,
  //       })
  //       .set(baseline.value, {
  //         opacity: 0,
  //         y: 20,
  //       })
  //       .set(text.value, {
  //         opacity: 0,
  //         y: 20,
  //       })
  //     if (isInViewport) {
  //       // Const tl = gsap.timeline()

  //       tl.add('onBoarding')
  //         .to(
  //           headline.value,
  //           {
  //             duration: 0.5,
  //             opacity: 1,
  //             y: 0,
  //             ease: 'power4.out',
  //           },
  //           '<+0.1'
  //         )
  //         .to(
  //           title.value,
  //           {
  //             duration: 0.5,
  //             opacity: 1,
  //             y: 0,
  //             ease: 'power4.out',
  //           },
  //           '<+0.1'
  //         )
  //         .to(
  //           baseline.value,
  //           {
  //             duration: 0.5,
  //             opacity: 1,
  //             stagger: 0.1,
  //             y: 0,
  //             ease: 'power4.out',
  //           },
  //           '<+0.1'
  //         )
  //         .to(
  //           text.value,
  //           {
  //             duration: 1,
  //             opacity: 1,
  //             y: 0,
  //             ease: 'power4.out',
  //           },
  //           '<+0.2'
  //         )
  //     }
  //   }

  //   return {
  //     headline,
  //     baseline,
  //     title,
  //     text,
  //     onAppear,
  //   }
  // },
})
