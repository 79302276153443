




import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-wysiwyg',
  props: {
    wysiwyg: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const template = `<div>${props.wysiwyg}</div>`
    const runtimeComponent = computed(() => ({
      template,
    }))

    return {
      runtimeComponent,
    }
  },
})
