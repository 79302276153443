




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-json-ld',
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
