



















































































































































































































































































































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { gsap } from 'gsap'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'
import SlideshowBtn from '@/components/SlideshowBtn.vue'

export default defineComponent({
  props: {
    content: Object,
  },
  components: { SlideshowBtn },
  setup(props) {
    const headlineRef = ref<HTMLElement | null>(null)
    const titleRef = ref<HTMLElement | null>(null)
    const textRef = ref<HTMLElement | null>(null)
    const pictureRef = ref<HTMLElement | null>(null)
    const pictureContainerRef = ref<HTMLElement | null>(null)
    const linkRef = ref<HTMLElement | null>(null)
    const wrapperRef = ref<HTMLElement | null>(null)
    const progressRef = ref<HTMLElement | null>(null)
    const prevBtnRef = ref<Vue | null>(null)
    const nextBtnRef = ref<Vue | null>(null)
    const counterRef = ref<HTMLElement | null>(null)
    const autoPlay = ref(true)
    const autoPlayTween = ref<gsap.core.Tween | null>(null)

    const currentSlideIndex = ref(0)

    const currentSlideExists = computed(
      () =>
        props?.content?.items &&
        Array.isArray(props.content.items) &&
        props.content.items[currentSlideIndex.value]
    )

    const picture = computed(() => {
      if (currentSlideExists.value) {
        return props?.content?.items[currentSlideIndex.value].picture
      }

      return ''
    })

    const slideEnter = (el, done) => {
      console.log('ENTER', currentSlideIndex.value)

      gsap
        .timeline()
        .addLabel('start')
        .fromTo(
          el.querySelectorAll('[data-animate="mask"]'),
          {
            clipPath: 'inset(0% 100% 0% 100%)',
          },
          {
            clipPath: 'inset(0% 0% 0% 0%)',
          },
          'start'
        )
        .fromTo(
          el.querySelectorAll(
            `[data-index="${currentSlideIndex.value}"] [data-animate="fade"]`
          ),
          {
            opacity: 0,
            y: 10,
          },
          {
            opacity: 1,
            y: 0,
            stagger: 0.1,
          },
          'start'
        )
        .then(done)
    }

    const slideLeave = (el, done) => {
      console.log(
        'LEAVE',
        currentSlideIndex.value,
        el.querySelectorAll(`[data-index="${currentSlideIndex.value}"]`)
      )

      gsap
        .timeline({
          defaults: {
            duration: 0.25,
          },
        })
        .addLabel('start')
        .fromTo(
          el.querySelectorAll('[data-animate="mask"]'),
          {
            clipPath: 'inset(0% 0% 0% 0%)',
          },
          {
            clipPath: 'inset(0% 0% 0 100%)',
          },
          'start'
        )
        .to(
          el.querySelectorAll(
            `[data-index="${currentSlideIndex.value}"] [data-animate="fade"]`
          ),
          {
            opacity: 0,
            y: -10,
          },
          'start'
        )
        .then(done)
    }

    const hasSlides = computed(
      () =>
        props?.content?.items &&
        Array.isArray(props.content.items) &&
        props.content.items.length > 1
    )

    const killAutoplay = () => {
      if (autoPlay.value) {
        autoPlay.value = false
        if (autoPlayTween.value) {
          autoPlayTween.value.kill()

          gsap.to(progressRef.value, {
            scaleY: 0,
            duration: 0.5,
          })
        }
      }
    }

    const prev = (kill = true) => {
      if (hasSlides.value) {
        if (currentSlideIndex.value === 0) {
          currentSlideIndex.value = props?.content?.items.length - 1
        } else {
          currentSlideIndex.value -= 1
        }

        if (kill) {
          killAutoplay()
        }
      }
    }

    const next = (kill = true) => {
      if (hasSlides.value) {
        if (currentSlideIndex.value === props?.content?.items.length - 1) {
          currentSlideIndex.value = 0
        } else {
          currentSlideIndex.value += 1
        }

        if (kill) {
          killAutoplay()
        }
      }
    }

    onMounted(() => {
      if (wrapperRef.value) {
        gsap.set(wrapperRef.value, {
          opacity: 0,
          yPercent: 10,
        })
      }

      if (pictureContainerRef.value) {
        pictureContainerRef.value.style.clip = 'rect(0, 0, 100vh, 0)'
      }
    })

    const startAutoplay = () => {
      autoPlayTween.value = gsap.fromTo(
        progressRef.value,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          ease: 'linear',
          duration: 6,
          onComplete: () => {
            if (autoPlay.value) {
              next(false)
              if (autoPlayTween.value) {
                autoPlayTween.value.restart()
              }
            }
          },
        }
      )
    }

    const onAppear = (isInViewport, isFullyInViewport) => {
      console.log('isInViewport', isInViewport, isFullyInViewport)

      if (isInViewport) {
        const width = pictureContainerRef.value?.offsetWidth
        const height = pictureContainerRef.value?.offsetHeight
        const clipProgress = {
          x: width,
        }

        const splitTitle = new SplitText(textRef.value, {
          type: 'lines',
        })

        const tl = gsap.timeline({
          onComplete: () => {
            if (hasSlides.value) {
              startAutoplay()
            }
          },
        })

        tl.add('onBoarding')
          .from(clipProgress, {
            duration: 1,
            x: 0,
            ease: 'power4.inOut',
            onUpdate: () => {
              if (pictureContainerRef.value) {
                pictureContainerRef.value.style.clip = `rect(0, ${clipProgress.x}px, ${height}px , 0 )`
              }
            },
          })
          .from(
            pictureRef.value,
            {
              duration: 1,
              scale: 1.2,
              x: -50,
              ease: 'power4.out',
            },
            '<+0.1'
          )
          .to(
            wrapperRef.value,
            {
              duration: 1,
              opacity: 1,
              yPercent: 0,
              ease: 'power4.out',
            },
            '<+0.5'
          )
          .from(
            headlineRef.value,
            {
              duration: 0.5,
              opacity: 0,
              y: 20,
              ease: 'power4.out',
            },
            '<+0.1'
          )
          .from(
            titleRef.value,
            {
              duration: 0.5,
              opacity: 0,
              y: 20,
              ease: 'power4.out',
            },
            '<+0.1'
          )
          .from(
            splitTitle.lines,
            {
              duration: 1,
              opacity: 0,
              stagger: 0.1,
              y: 20,
              ease: 'power4.out',
            },
            '<+0.2'
          )
          .from(
            linkRef.value,
            {
              duration: 0.5,
              opacity: 0,
              y: 20,
              ease: 'power4.out',
            },
            '<+0.2'
          )

        if (prevBtnRef.value) {
          tl.from(
            prevBtnRef.value.$el,
            {
              duration: 0.5,
              opacity: 0,
              y: 20,
              ease: 'power4.out',
            },
            '<+0.2'
          )
        }

        if (nextBtnRef.value) {
          tl.from(
            nextBtnRef.value.$el,
            {
              duration: 0.5,
              opacity: 0,
              y: 20,
              ease: 'power4.out',
            },
            '<+0.2'
          )
        }

        if (counterRef.value) {
          tl.from(
            counterRef.value,
            {
              duration: 0.5,
              opacity: 0,
              y: 20,
              ease: 'power4.out',
              clearProps: 'all',
            },
            '<+0.2'
          )
        }

        // Scroll Handling
      }
    }

    return {
      headlineRef,
      titleRef,
      textRef,
      onAppear,
      pictureRef,
      pictureContainerRef,
      linkRef,
      wrapperRef,
      prevBtnRef,
      nextBtnRef,
      progressRef,
      counterRef,

      hasSlides,
      currentSlideIndex,
      picture,

      slideEnter,
      slideLeave,

      prev,
      next,
    }
  },
})
