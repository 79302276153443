













































































































import { defineComponent } from '@vue/composition-api'
import ContactLink from '@/components/contact/Link.vue'

export default defineComponent({
  name: 'contact-related',
  components: {
    ContactLink,
  },
  props: {
    content: {
      type: Object,
      default: () => '',
    },
  },
})
