
























import { defineComponent, ref } from '@vue/composition-api'
import { mapActions } from '@/store/utils'
import { GenericObject } from '@/inc/types'
import gsap from 'gsap'

export default defineComponent({
  props: {
    content: {
      type: Array || null,
      default: () => [],
    },
  },

  setup(props, ctx) {
    const pictures = ref<GenericObject | null>(null)
    const { removePictures } = mapActions(ctx, 'pictures', ['removePictures'])

    const anims = {
      beforeEnter(el) {
        gsap.set(el, { opacity: 0 })
      },
      enter(el, done) {
        const body = document.querySelector('body')

        body && body.classList.add('is-locked')

        gsap.to(el, 0.5, {
          opacity: 1,
          ease: 'power4.out',
          onComplete: () => {
            done()
          },
        })
      },
      afterEnter() {
        setTimeout(() => {
          console.log(pictures)
        }, 300)
      },
      leave(el, done) {
        const body = document.querySelector('body')

        body && body.classList.remove('is-locked')

        gsap.to(el, 0.5, {
          opacity: 0,
          ease: 'power4.inOut',
          onComplete: () => {
            pictures.value && pictures.value.destroy()
            done()
          },
        })
      },
    }

    return {
      anims,
      removePictures,
    }
  },
})
