


















































































































































































































































































































































































































import { ref, onMounted, defineComponent } from '@vue/composition-api'
import { gsap } from 'gsap'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'

export default defineComponent({
  props: {
    content: Object,
    mode: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'xl',
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  setup(props, ctx) {
    const spacerClass = `h-spacer-${
      props.mode === 'shaded' || props.mode === 'home' ? 'xxl' : props.size
    }`

    const hero = ref<HTMLElement | null>(null)
    const onboard = ref<HTMLElement | null>(null)
    const text = ref<HTMLElement | null>(null)
    const picture = ref<HTMLElement | null>(null)
    const pictureContainer = ref<HTMLElement | null>(null)
    const cta = ref<HTMLElement | null>(null)
    const ticket = ref<HTMLElement | null>(null)

    const basicScale = 1.1
    const handlingScroll = () => {
      const normalizeScale = window.scrollY / 8000
      const scale = basicScale + normalizeScale
      // If (window.scrollY >= window.innerHeight) {
      //   if (hero.value) {
      //     hero.value.classList.add('stop-scroll')
      //   }
      //   window.removeEventListener('scroll', handlingScroll)
      // }
      gsap.to(picture.value, {
        duration: 1,
        y: window.scrollY / 10,
        scale,
        ease: 'power4.out',
      })
    }

    onMounted(() => {
      const width = pictureContainer.value?.offsetWidth
      const height = pictureContainer.value?.offsetHeight
      const clipProgress = {
        x: width,
      }

      const splitTitle = new SplitText(text.value, {
        type: 'words',
      })
      const tl = gsap.timeline({
        delay: ctx.root.$route.path === '/' ? 1.6 : 0,
      })

      tl.add('onBoarding')
        .from(
          clipProgress,
          {
            duration: 1,
            x: 0,
            ease: 'power4.inOut',
            onUpdate: () => {
              if (pictureContainer.value) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                pictureContainer.value!.style.clip = `rect(0, ${clipProgress.x}px, ${height}px , 0 )`
              }
            },
          },
          '<+0'
        )
        .to(
          picture.value,
          {
            duration: 1,
            scale: 1.1,
            ease: 'power4.out',
          },
          '<+0.1'
        )
        .from(
          splitTitle.words,
          {
            duration: 2,
            opacity: 0,
            stagger: 0.1,
            y: 20,
            ease: 'power4.out',
          },
          '<+0.2'
        )
        .from(
          cta.value,
          {
            duration: 0.7,
            opacity: 0,
            // Y: 20,
            ease: 'power4.out',
          },
          '<+0.2'
        )
        .from(
          ticket.value,
          {
            duration: 0.7,
            opacity: 0,
            // Y: 20,
            ease: 'power4.out',
          },
          '<+0.2'
        )

      // Scroll Handling
      window.addEventListener('scroll', handlingScroll)
    })

    return {
      cta,
      hero,
      onboard,
      text,
      picture,
      pictureContainer,
      spacerClass,
      ticket,
    }
  },
})
