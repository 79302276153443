

































































































































































import { defineComponent } from '@vue/composition-api'
import ContactItem from '@/components/contact/Item.vue'

export default defineComponent({
  name: 'contact-address',
  props: {
    content: Object,
    noHeadline: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    ContactItem,
  },
})
