








































































































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Object,
  },

  setup(props, ctx) {
    const closeMega = () => {
      ctx.root.$ee.$emit('close-menu')
    }

    return {
      closeMega,
    }
  },
})
