



























































































































































import { defineComponent, ref } from '@vue/composition-api'
// eslint-disable-next-line
export default defineComponent({
  props: {
    content: Array,
  },

  setup() {
    const isOpen = ref(false)
    const toggle = () => {
      isOpen.value = !isOpen.value
    }
    const close = () => {
      isOpen.value = false
    }

    return {
      close,
      isOpen,
      toggle,
    }
  },
})
