
























































































































































































import ContactAddress from '@/components/contact/Address.vue'
import ContactPeople from '@/components/contact/People.vue'
import ContactRelated from '@/components/contact/Related.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-contact',
  props: {
    content: Object,
  },
  components: {
    ContactAddress,
    ContactPeople,
    ContactRelated,
  },
})
