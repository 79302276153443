import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import { guards } from './guards'

Vue.use(VueRouter)

export default function createRouter(): VueRouter {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(_to, _from, savedPosition) {
      console.info('SCROLL: ', savedPosition)

      return { x: 0, y: 0 }
    },
  })

  guards(router)

  return router
}
