<template>
  <div
    class="tag"
    :class="{
      'is-selected': selected,
      'is-light': light,
      'is-clickable': clickable,
    }"
  >
    <div class="tag__label" v-html="label"></div>
    <svg
      class="tag__icon"
      v-svg
      size="0 0 14 18"
      symbol="ui-plus"
      v-if="type === 'add'"
    />
    <svg
      class="tag__icon"
      v-svg
      size="0 0 14 18"
      symbol="ui-cross"
      v-if="type === 'remove'"
    />
  </div>
</template>

<style lang="scss" scoped>
.tag {
  display: flex;
  align-items: center;
  padding: $spacing / 2.5 $spacing / 2;
  border: 1px solid $c-green-light;
  border-radius: 15px;
  transition: color 0.2s ease-out, border 0.2s ease-out;

  &.is-light {
    color: $c-green-dark;
  }

  &.is-clickable {
    cursor: pointer;
  }

  &.is-clickable:hover {
    color: $c-yellow-medium;
    border: 1px solid $c-yellow-medium;
  }

  &.is-selected {
    color: $c-gray-dark;
    border: 1px solid $c-gray-dark;
    cursor: default;
  }
}

.tag__label {
  @extend %text-uppercase;
  @extend %fw-bold;
  @extend %ff-alt;

  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  letter-spacing: 0.1em;
}

.tag__icon {
  width: 12px;
  height: 12px;
  fill: $c-green-light;
  margin-left: $spacing / 4;
  transition: fill 0.2s ease-out, border 0.2s ease-out;

  .tag.is-clickable:hover & {
    fill: $c-yellow-medium;
  }
}
</style>

<script>
export default {
  props: {
    label: null,
    selected: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    type: null,
  },
}
</script>
