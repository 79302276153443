






































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import {
  Nav,
  TmpNav,
  NavChild,
  NavItem,
  Link,
  Menu,
  SpecificObject,
} from '@/inc/types'
import MegaMenu from './MegaMenu.vue'
import NavMenu from './NavMenu.vue'

import Headroom from 'headroom.js'

export default defineComponent({
  components: {
    MegaMenu,
    NavMenu,
  },

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const { chrome } = mapGetters(ctx, ['chrome'])
    const navElement = ref<null | HTMLElement>(null)

    const stickyNav = () => {
      const headroom = new Headroom(navElement.value)

      console.log('stickyNav')

      headroom.init()
    }

    onMounted(() => {
      const nav: Nav = (chrome.value.main as TmpNav).map(navItem => {
        const { label, children: tmpChildren } = navItem
        const tmp: SpecificObject<Menu> = {}

        tmpChildren.forEach(tmpChild => {
          const { label, url, type } = tmpChild
          const link: Link = {
            label,
            url,
          }
          const topLabel = type || 'default'

          if (tmp[topLabel]) {
            tmp[topLabel].push(link)
          } else {
            tmp[topLabel] = [link]
          }
        })

        const children: NavChild[] = []

        Object.keys(tmp).forEach(label => {
          children.push({
            label,
            items: tmp[label],
          })
        })

        return {
          label,
          children,
        } as NavItem
      })

      stickyNav()

      return {
        nav,
      }
    })

    return {
      navElement,
    }
  },
})
