









































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-btn',
  props: {
    content: Object,
    tag: {
      type: String,
      default: 'a',
    },
  },
})
