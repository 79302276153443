

























import { defineComponent, PropType } from '@vue/composition-api'

interface CookieItem {
  title: string
  description: string
  domain: string
  lifetime: string
}

interface CookiesList {
  title: string
  description: string
  items: CookieItem[]
}

export default defineComponent({
  name: 'cookies',
  props: {
    content: {
      type: Array as PropType<CookiesList[]>,
      required: true,
    },
  },
})
