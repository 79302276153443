import axios, { AxiosResponse } from 'axios'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import * as cache from '@/inc/utils/cache'
import { getApiUrl } from '@/inc/app.config'
import { Chrome, ChromeState, RootState } from '@/inc/types'

const state: ChromeState = {
  data: {} as Chrome,
}

const getters: GetterTree<ChromeState, RootState> = {
  chrome: state => state.data,
}

const mutations: MutationTree<ChromeState> = {
  SET_CHROME(state, payload: Chrome) {
    if (payload) {
      state.data = payload
    }
  },
}

const actions: ActionTree<ChromeState, RootState> = {
  async fetchChrome({ commit }, payload) {
    let response: AxiosResponse<Chrome>

    if (payload?.type === 'server') {
      response = await axios.get(`${getApiUrl()}/chrome`)
    } else {
      const path = 'chrome'

      response = (await cache.fetch(
        path,
        `${getApiUrl()}/chrome`
      )) as AxiosResponse<Chrome>
    }
    const data: Chrome = response?.data

    commit('SET_CHROME', data)
  },
}

export const chrome: Module<ChromeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
