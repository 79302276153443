



























import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'g-picture',
  props: {
    content: Object,
  },
  setup() {
    const picture = ref<HTMLElement | null>(null)
    const pictureContainer = ref<HTMLElement | null>(null)
    const onAppear = isInViewport => {
      const tl = gsap.timeline()
      if (isInViewport) {
        const width = pictureContainer.value?.offsetWidth
        const height = pictureContainer.value?.offsetHeight
        const clipProgress = {
          x: width,
        }

        tl.add('onBoarding')
          .from(clipProgress, {
            duration: 1,
            x: 0,
            ease: 'power4.inOut',
            onUpdate: () => {
              if (picture.value) {
                picture.value.style.clip = `rect(0, ${clipProgress.x}px, ${height}px , 0 )`
              }
            },
          })
          .from(
            picture.value,
            {
              duration: 1,
              scale: 1.2,
              x: -50,
              ease: 'power4.out',
            },
            '<+0.1'
          )
        // Scroll Handling
      }
    }

    return {
      onAppear,
      picture,
      pictureContainer,
    }
  },
})
