

























































import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'
export default defineComponent({
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const item = ref<HTMLElement | null>(null)

    const onAppear = isInViewport => {
      const tl = gsap.timeline()
      tl.set(item.value, {
        opacity: 0,
        y: 20,
      })
      if (isInViewport) {
        tl.to(item.value, {
          duration: 0.3,
          opacity: 1,
          stagger: 0.1,
          y: 0,
        })
      }
    }

    return {
      item,
      onAppear,
    }
  },
})
