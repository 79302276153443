

























import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Object,
    default: () => ({}),
  },

  setup(props) {
    const keyfacts = computed(() => {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const arr = props.content!.keyfacts
        ? props.content!.keyfacts
        : props.content!.items
      /* eslint-enable @typescript-eslint/no-non-null-assertion */

      return arr
    })

    return {
      keyfacts,
    }
  },
})
