



















































import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import { push, GtmFollow } from '@/inc/utils'

export default defineComponent({
  name: 'g-socialnetwork',
  props: {
    content: Array,
  },
  setup(props, ctx) {
    const onClick = slug => {
      if (ctx.root.$router.$$type === 'client') {
        const layer: GtmFollow = {
          event: 'social-follow',
          eventCategory: 'social',
          eventAction: 'follow',
          eventLabel: slug,
        }

        // DGTM 5.2.5
        push(layer)
      }
    }

    return {
      ...mapGetters(ctx, ['chrome']),
      onClick,
    }
  },
})
