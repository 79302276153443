import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

import { Chrome, Resource, Searchtool } from '@/inc/types'

type ApiResponse = Chrome | Resource | Searchtool

/**
 * Cache HTTP responses
 */
export const data: Map<string, Promise<AxiosResponse<ApiResponse>>> = new Map()
export const fetch = (
  path: string,
  url: string,
  config?: AxiosRequestConfig
) => {
  if (!data.has(path)) {
    data.set(path, axios.get(url, config))
  }

  return data.get(path)
}
