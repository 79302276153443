































































































































































import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import {
  Nav,
  TmpNav,
  NavChild,
  NavItem,
  Link,
  Menu,
  SpecificObject,
} from '@/inc/types'
import NavSub from './NavSub.vue'
import UniverseSwitcher from './UniverseSwitcher.vue'

export default defineComponent({
  components: {
    NavSub,
    UniverseSwitcher,
  },

  props: {
    content: Object,
  },

  setup(props, ctx) {
    const { chrome } = mapGetters(ctx, ['chrome'])
    console.log()
    const nav: Nav = (chrome.value.main as TmpNav).map(navItem => {
      const { label, children: tmpChildren } = navItem
      const tmp: SpecificObject<Menu> = {}

      if (tmpChildren) {
        tmpChildren.forEach(tmpChild => {
          const { label, url, type } = tmpChild
          const link: Link = {
            label,
            url,
          }
          const topLabel = type || 'default'

          if (tmp[topLabel]) {
            tmp[topLabel].push(link)
          } else {
            tmp[topLabel] = [link]
          }
        })
      }

      const children: NavChild[] = []

      Object.keys(tmp).forEach(label => {
        children.push({
          label,
          items: tmp[label],
        })
      })

      return {
        label,
        children,
      } as NavItem
    })

    return {
      nav,
    }
  },
})
