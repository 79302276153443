






























































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-packages',
  props: {
    title: String,
    content: {
      type: Array,
      default: () => [],
    },
    infoBubbles: {
      type: Array,
      default: () => [],
    },
  },
})
