






































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Array,
    light: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
})
