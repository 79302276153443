import consola from 'consola'

const { VUE_APP_RELEASE } = process.env

consola.level = Infinity

if (VUE_APP_RELEASE === 'production') {
  // DEV
  // consola.pauseLogs()
  // consola.level = 1
  // consola.removeReporter()
}

export default consola
