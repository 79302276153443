

























import { defineComponent } from '@vue/composition-api'
import SignatoriesGrid from '@/components/SignatoriesGrid.vue'

export default defineComponent({
  props: {
    content: Object,
  },
  components: {
    SignatoriesGrid,
  },
})
