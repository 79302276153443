











































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'contact-link',
  props: {
    content: Object,
  },
  setup(props) {
    let type

    if (props.content?.target === '_blank') {
      type = 'external'
    } else if (props.content?.extension) {
      type = 'download'
    } else {
      type = 'internal'
    }

    return {
      type,
    }
  },
})
