






















































































































































































import { mapGetters } from '@/store/utils'
import { push, GtmRegister } from '@/inc/utils'

export default {
  props: {
    content: Object,
  },
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    const onClick = () => {
      if (ctx.root.$router.$$type === 'client') {
        const layer: GtmRegister = {
          event: 'outbound-link',
          eventCategory: 'outbound-link',
          eventAction: `${content.value.hero.category}-register`,
          eventLabel: content.value.hero.title,
        }

        // DGTM 5.2.7
        push(layer)
      }
    }

    return {
      onClick,
    }
  },
}
