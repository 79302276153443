<template>
  <div class="hero-cta">
    <div class="hero-cta__row">
      <div class="hero-cta__row__label" v-html="content.title"></div>
    </div>
    <a class="hero-cta__link" :href="content.link.url" v-if="content.link">
      <div class="hero-cta__link__label">
        {{ content.link.title }}
      </div>
      <svg
        class="hero-cta__link__arrow"
        v-svg
        size="0 0 12 10"
        symbol="ui-arrow-right"
      ></svg>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.hero-cta {
  @extend %ff-alt;

  overflow: hidden;
  width: 100%;
  background-color: $c-white;
  border-radius: 5px;
}

.hero-cta__row {
  margin: 0 $spacing * 2;
  padding: $spacing * 1.5 0;

  &:first-child {
    padding-top: $spacing * 2;
  }

  & + & {
    border-top: 1px dashed $c-gray-dark;
  }
}

.hero-cta__row__label {
  color: $c-green-dark;
  font-size: 2.6rem;
  line-height: 1.3;
}

.hero-cta__row__value {
  @extend %fw-bold;

  margin-top: $spacing / 2;
  color: $c-green-dark;
  font-size: 1.8rem;
  line-height: 1.2em;
}

.hero-cta__row__value__logo-outer {
  display: none;
}

.hero-cta__link {
  @extend %text-nodecoration;
  @extend %fw-bold;

  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 2.6rem $spacing * 2;
  color: $c-white;
  font-size: 1.8rem;
  line-height: 1em;
  background-color: $c-yellow-medium;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: darken($c-yellow-medium, 10%);
  }
}

.hero-cta__link__arrow {
  position: relative;
  top: 1px;
  width: 12px;
  height: 10px;
  margin-left: $spacing / 2;
  fill: $c-white;
  transition: transform 0.2s ease-out;

  .hero-cta__link:hover & {
    transform: translateX(5px);
  }
}
</style>

<script>
export default {
  name: 'g-hcta',
  props: {
    content: Object,
  },
}
</script>
