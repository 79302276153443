import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Cinema, CinemaState, RootState } from '@/inc/types'

const state: CinemaState = {
  data: {
    id: null,
  } as Cinema,
}

const getters: GetterTree<CinemaState, RootState> = {
  cinema: state => state.data,
  cinemaId: state => state.data.id,
}

const mutations: MutationTree<CinemaState> = {
  CINEMA_ADD(state, payload: Cinema) {
    Object.assign(state.data, payload)
  },
  CINEMA_REMOVE(state) {
    state.data.id = null
  },
}

const actions: ActionTree<CinemaState, RootState> = {
  addId({ commit }, payload) {
    commit('CINEMA_ADD', payload)
  },
  removeId({ commit }, payload) {
    commit('CINEMA_REMOVE', payload)
  },

  /* !DEV */
  /* async fetchChrome({ commit }, payload) { */
  /*   let response: AxiosResponse<Chrome> */
  /*   if (payload?.type === 'server') { */
  /*     response = await axios.get(`${API}/chrome`) */
  /*   } else { */
  /*     const path = 'chrome' */
  /*     response = (await cache.fetch(path, `${API}/chrome`)) as AxiosResponse< */
  /*       Chrome */
  /*     > */
  /*   } */
  /*   const data: Chrome = response?.data */
  /*   commit('SET_CHROME', data) */
  /* }, */
}

export const cinema: Module<CinemaState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
