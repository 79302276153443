











































































































































































































































































































































































































































































































































































































// Card.vue.ts
import { defineComponent } from '@vue/composition-api'
import { mapActions } from '@/store/utils'
// !DEV
// import { push, GtmDownload } from '@/inc/utils'

export default defineComponent({
  props: {
    content: Object,
  },

  setup(props, ctx) {
    const { addPopupContent } = mapActions(ctx, 'popup', ['addPopupContent'])
    const { addPictures } = mapActions(ctx, 'pictures', ['addPictures'])
    const openPopup = card => {
      addPopupContent(card)
    }

    const openGallery = card => {
      addPictures(card)
    }

    return {
      openGallery,
      openPopup,
    }
  },
})
