import Vue from 'vue'
import Vuex, { Store, StoreOptions } from 'vuex'

import { RootState } from '@/inc/types'
// Modules
import { cinema } from './modules/cinema'
import { chrome } from './modules/chrome'
import { pictures } from './modules/pictures'
import { popup } from './modules/popup'
import { resource } from './modules/resource'
import { searchtool } from './modules/searchtool'
// End modules

Vue.use(Vuex)

export default function createStore(): Store<RootState> {
  const store: StoreOptions<RootState> = {
    state: {
      version: '1.0.0',
    },
    modules: {
      cinema,
      chrome,
      pictures,
      popup,
      resource,
      searchtool,
    },
  }

  return new Vuex.Store<RootState>(store)
}
