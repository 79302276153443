




















































































import { defineComponent, PropType } from '@vue/composition-api'

interface Bubble {
  title: string
  text: string
}

export default defineComponent({
  name: 'g-package',

  props: {
    content: {
      type: Object,
      default: () => '',
    },
    bubbles: {
      type: Array as PropType<Bubble[]>,
      default: () => [],
    },
  },
})
