












































































































































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'contact-people',
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
})
