import { AxiosResponse, AxiosRequestConfig } from 'axios'
import VueRouter, { Route } from 'vue-router'

import { endpointDefault, getApiUrl } from '@/inc/app.config'
import { cache, logger } from '@/inc/utils'
import { Resource } from '@/inc/types'

export const parseRoute = (to: Route) => {
  const { path, meta, params, query } = to
  const api = meta?.api || getApiUrl()
  let endpoint = endpointDefault
  let resource = path

  if (params?.lang) {
    // Remove lang from path
    resource = resource.replace(new RegExp(`^/${params.lang}`), '')
  }

  if (meta?.endpoint) {
    // Set API endpoint
    ;({ endpoint } = meta)
    // Remove endpoint from path
    resource = resource.replace(
      new RegExp(`^/${meta.endpoint || params.pathMatch}`),
      ''
    )
  }

  if (query?.preview === 'true') {
    // Manage preview
    const { page_id: pageId } = query

    if (pageId) {
      resource = `/${pageId}`
    }
  }

  resource = resource.replace(/^\//, '')

  return {
    api,
    endpoint,
    resource,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const save = (ctx: any | VueRouter, data: Resource, ssr: boolean) => {
  if (ssr) {
    // Save to server response
    ctx.$resource = data
  } else {
    // Can not commit the store here
    // It's not compatible with VueJS lifecycle: content changed but views/components did not yet…
    // So there are matching error between new content from store and existing (old) components
    // We should use route watcher (App)
    // ctx.app.$store.commit('SET_RESOURCE', data)

    // Save to router object
    ctx.$resource = data
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetch(to: Route, ctx: any | VueRouter, ssr = false) {
  const config: AxiosRequestConfig = {}
  const { fullPath, meta, query } = to

  // No fetch for static routes
  // !TODO: what about metadata (title, head, …)
  if (meta?.static) {
    ctx.$resource = {
      tracking: {},
      content: {},
      languages: {},
    }

    return
  }

  const { api, endpoint, resource } = parseRoute(to)

  config.params = query

  if (query?.preview === 'true') {
    // Preview header
    config.headers = {
      'x-preview': true,
    }
    delete config.params.preview_nonce
  }

  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetch]', url)

  try {
    if (ssr) {
      // No cache server-side because instance is "shared" across all clients, forever :)
      cache.data.clear()
    }
    const response = (await cache.fetch(
      fullPath,
      url,
      config
    )) as AxiosResponse<Resource>

    // Nested, so what?
    // if (params.nested || meta.nested) {}
    save(ctx, response.data, ssr)
  } catch (error) {
    const { response } = error

    if (response && response.status) {
      const { status, statusText } = response

      if (ssr) {
        // Add 404 status to the server response
        ctx.statusCode = status
        ctx.statusMessage = statusText
      }

      status === 404 && save(ctx, response.data, ssr)
    } else {
      console.error(error)
    }
  }
}
