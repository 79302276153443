import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Popup, PopupState, RootState } from '@/inc/types'

const state: PopupState = {
  data: {
    popup: null,
  } as Popup,
}

const getters: GetterTree<PopupState, RootState> = {
  popup: state => state.data,
  popupContent: state => state.data.popup,
}

const mutations: MutationTree<PopupState> = {
  POPUP_ADD(state, payload: Popup) {
    Object.assign(state.data, payload)
  },
  POPUP_REMOVE(state) {
    state.data.popup = null
  },
}

const actions: ActionTree<PopupState, RootState> = {
  addPopupContent({ commit }, payload) {
    commit('POPUP_ADD', payload)
  },
  removePopupContent({ commit }, payload) {
    commit('POPUP_REMOVE', payload)
  },
}

export const popup: Module<PopupState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
