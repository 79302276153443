






































































































































































































































































interface PageNavItem {
  label: string
  value?: string
  url?: string
}

import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
import { isExternalURL } from '@/inc/utils'
import { throttle } from 'throttle-debounce'

export default defineComponent({
  name: 'g-page-nav',
  props: {
    content: {
      type: Array as () => PageNavItem[],
      default: () => [],
    },
    sticky: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, ctx) {
    const isOpen = ref(false)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const close = () => {
      isOpen.value = false
    }

    const tmpList = props.content?.map(c => ({
      label: c.label,
      url: c.url ? c.url : c.value,
    }))

    const label = computed(() => {
      const r = tmpList.find(c => c.url === ctx.root.$route.path)

      return r ? r.label : 'Change page'
    })

    const rootRef = ref<HTMLElement>()
    const isStuck = ref<boolean>(false)
    const scrollDirection = ref(0) // 1 === scrolling down, -1 === scrolling up
    let previousScrollPos = 0 // No need for reactivity
    let navOffset = Infinity // No need for reactivity

    const getScrollPos = () => window.scrollY || window.pageYOffset

    const getNavOffset = () => {
      if (rootRef.value) {
        const currentScrollPos = getScrollPos()

        return rootRef.value.getBoundingClientRect().top - currentScrollPos
      }

      return Infinity
    }

    const onScroll = throttle(100, () => {
      const currentScrollPos = getScrollPos()

      scrollDirection.value = Math.sign(currentScrollPos - previousScrollPos)

      isStuck.value = currentScrollPos >= navOffset

      previousScrollPos = currentScrollPos
    })

    const onResize = throttle(100, () => {
      navOffset = getNavOffset()
    })

    onMounted(() => {
      if (props.sticky && rootRef.value) {
        navOffset = getNavOffset()
        window.addEventListener('scroll', onScroll)
        window.addEventListener('onResize', onResize)
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)
      onScroll.cancel()
      onResize.cancel()
    })

    return {
      close,
      isOpen,
      isExternalURL,
      label,
      toggle,
      tmpList,
      rootRef,
      isStuck,
      scrollDirection,
    }
  },
})
