






















































































import { computed, defineComponent } from '@vue/composition-api'
import { push, GtmContact } from '@/inc/utils'

export default defineComponent({
  name: 'contact-item',
  props: {
    content: Object,
  },
  setup(props, ctx) {
    const icons = {
      pin: {
        size: '0 0 15 19',
        symbol: 'map-pin',
      },
      email: {
        size: '0 0 16 17',
        symbol: 'ui-bubble',
      },
    }

    const icon = computed(() => {
      // eslint-disable-next-line
      if (!props.content!.icon) {
        return null
      }

      return icons[props.content!.icon] // eslint-disable-line
    })

    const onClick = label => {
      if (ctx.root.$router.$$type === 'client') {
        const layer: GtmContact = {
          event: 'contact',
          eventCategory: 'contact',
          eventAction: 'click',
          eventLabel: label,
        }

        // DGTM 5.2.8
        push(layer)
      }
    }

    return {
      icon,
      onClick,
    }
  },
})
