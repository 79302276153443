import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

import { langDefault, langAvailable } from '@/inc/app.config'
import View from '@/views/View.vue'

// Format segments to (a|b)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const formatSegment = (key: string): string =>
//   Array.isArray(segments[key]) ? `(${segments[key].join('|')})` : segments[key]

let routes: RouteConfig[] = [
  {
    path: '/jobs/',
    name: 'jobs',
    component: () =>
      import(/* webpackChunkName: 'v-jobs' */ '@/views/Jobs.vue'),
    meta: {
      ssr: true,
    },
  },
  {
    path: '/campaigns/:slug*',
    name: 'campaigns',
    component: () =>
      import(/* webpackChunkName: 'v-campaigns' */ '@/views/Campaigns.vue'),
    meta: {
      ssr: true,
      endpoint: 'campaigns',
    },
  },
  {
    path: '/events/:slug',
    component: () =>
      import(/* webpackChunkName: 'v-events' */ '@/views/Events.vue'),
    children: [
      {
        path: ':sub?',
        name: 'events-sub',
        component: () =>
          import(
            /* webpackChunkName: 'v-events-sub' */ '@/views/events/Sub.vue'
          ),
        meta: {
          ssr: true,
          key: 'event',
          endpoint: 'events',
        },
      },
    ],
  },
  {
    path: '/trainings/:slug',
    component: () =>
      import(/* webpackChunkName: 'v-events' */ '@/views/Events.vue'),
    children: [
      {
        path: ':sub?',
        name: 'trainings-sub',
        component: () =>
          import(
            /* webpackChunkName: 'v-events-sub' */ '@/views/events/Sub.vue'
          ),
        meta: {
          ssr: true,
          endpoint: 'trainings',
        },
      },
    ],
  },
  {
    path: '/news/:slug*',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: 'v-news' */ '@/views/News.vue'),
    meta: {
      ssr: true,
      endpoint: 'news',
    },
  },
  {
    path: '/projects/:slug*',
    name: 'projects',
    component: () =>
      import(/* webpackChunkName: 'v-projects' */ '@/views/Projects.vue'),
    meta: {
      ssr: true,
      endpoint: 'projects',
    },
  },
  {
    path: '/publications/:slug*',
    name: 'publications',
    component: () =>
      import(
        /* webpackChunkName: 'v-publications' */ '@/views/Publications.vue'
      ),
    meta: {
      ssr: true,
      endpoint: 'publications',
    },
  },
  {
    path: '/case-studies/:slug',
    name: 'case-studies',
    component: () =>
      import(/* webpackChunkName: 'v-case-studies' */ '@/views/CaseStudy.vue'),
    meta: {
      ssr: true,
      endpoint: 'case-studies',
    },
  },
  {
    path: '/regions/:slug*',
    name: 'regions',
    component: () =>
      import(/* webpackChunkName: 'v-regions' */ '@/views/Regions.vue'),
    meta: {
      ssr: true,
      endpoint: 'regions',
    },
  },
  {
    path: '/topics/:slug*',
    name: 'topics',
    component: () =>
      import(/* webpackChunkName: 'v-topics' */ '@/views/Topics.vue'),
    meta: {
      ssr: true,
      endpoint: 'topics',
    },
  },
  {
    path: '/search-tool/',
    name: 'searchtool',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      key: 'searchtool',
      ssr: true,
    },
  },

  {
    path: '/:pattern(signatories)/:slug*',
    name: 'signatories',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/pages/Signatories.vue'),
    meta: {
      key: 'signatories',
      ssr: true,
    },
  },
  {
    path: '/*',
    name: 'all',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: View as VueConstructor<Vue>,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: routes.map(r => {
        // Remove leading slash
        r.path = r.path.replace(/^\//, '')

        return r
      }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
    {
      path: '*',
      name: 'catch',
      component: () =>
        import(/* webpackChunkName: 'v-notfound' */ '@/views/NotFound.vue'),
      meta: {
        ssr: true,
      },
    },
  ]
}

export { routes }
