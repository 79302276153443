var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('section',{staticClass:"general-contact"},[_c('div',{staticClass:"general-contact-inner h-wrapper h-spacer-l"},[_c('div',{staticClass:"general-contact__title"},[_vm._v(" General information ")]),_c('div',{staticClass:"general-contact__infos"},[_c('div',{staticClass:"general-contact__infos__col"},[(_vm.content.email)?_c('contact-item',{staticClass:"general-contact__content__item",attrs:{"content":{
            headline: 'Email',
            link: _vm.content.email,
            icon: 'email',
          }}}):_vm._e(),(_vm.content.phone)?_c('contact-item',{staticClass:"general-contact__content__item",attrs:{"content":{
            headline: 'Telephone',
            link: _vm.content.phone,
          }}}):_vm._e(),(_vm.content.fax)?_c('contact-item',{staticClass:"general-contact__content__item",attrs:{"content":{
            headline: 'Fax',
            text: _vm.content.fax,
          }}}):_vm._e()],1),_c('div',{staticClass:"general-contact__infos__col"},[(_vm.content.address)?_c('contact-item',{staticClass:"general-contact__content__item",attrs:{"content":{
            text: _vm.content.address,
            icon: 'pin',
          }}}):_vm._e()],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }