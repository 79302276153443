

























































































































































































































































































































import ContactItem from '@/components/contact/Item.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-general-contact',
  props: {
    content: Object,
  },
  components: {
    ContactItem,
  },
})
