












































































// Legal.vue.ts
import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'footerLegal',

  setup(props, ctx) {
    const year = new Date().getFullYear()

    const showCookieSettings = () => {
      window.__cookieBar__ && window.__cookieBar__.edit()
    }

    return {
      ...mapGetters(ctx, ['chrome']),
      showCookieSettings,
      year,
    }
  },
})
