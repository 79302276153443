





































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions } from '@/store/utils'

export default defineComponent({
  name: 'g-video',
  setup(props, ctx) {
    const video = ref<null | HTMLElement>(null)
    const { addId } = mapActions(ctx, 'cinema', ['addId'])
    const openCinema = id => {
      console.log('HelloCinema')
      addId({ id })
    }

    return {
      openCinema,
      video,
    }
  },

  props: {
    content: Object,
  },
})
