

































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Object,
  },
  setup() {
    const networks = [
      {
        name: 'linkedin',
        url: 'https://google.net',
      },
      {
        name: 'facebook',
        url: 'https://google.net',
      },
      {
        name: 'twitter',
        url: 'https://google.net',
      },
    ]

    return {
      networks,
    }
  },
})
