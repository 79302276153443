

























import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  contentTemplates,
  getVirtualPage,
  push,
  GtmDownload,
} from '@/inc/utils'
import ChromeFooter from '@/components/chrome/Footer.vue'
import ChromeNav from '@/components/chrome/Nav.vue'
import Cinema from '@/components/chrome/Cinema.vue'
import Pictures from '@/components/chrome/Pictures.vue'
import NotFound from '@/views/NotFound.vue'
import Popup from '@/components/chrome/Popup.vue'

const gtmEvent = (template: string) => push(getVirtualPage(template))

export default Vue.extend({
  name: 'app',
  computed: {
    ...mapGetters('cinema', ['cinema']),
    ...mapGetters('pictures', ['pictures']),
    ...mapGetters('popup', ['popup']),
    ...mapGetters(['meta', 'chrome', 'template', 'tracking', 'content']),
    microdata: () => {
      /* eslint-disable quote-props */
      const data = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: 'UITP',
        url: 'https://www.uitp.org',
        potentialAction: {
          '@type': 'SearchAction',
          target: 'https://www.uitp.org/search-tool/{search_term_string}',
          'query-input': 'required name=search_term_string',
        },
      }
      /* eslint-enable quote-props */

      return data
    },
  },
  components: {
    ChromeFooter,
    ChromeNav,
    Cinema,
    Pictures,
    NotFound,
    Popup,
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
    }),
    ...mapActions(['fetchChrome']),
    click(e) {
      if (!e.target.hasAttribute('download')) {
        return
      }

      const t = e.target

      const title =
        t.getAttribute('data-document-title') &&
        t.getAttribute('data-document-title') !== ''
          ? t.getAttribute('data-document-title')
          : t.innerText

      const type =
        t.getAttribute('data-document-type') &&
        t.getAttribute('data-document-type') !== ''
          ? t.getAttribute('data-document-type')
          : ''

      const topics =
        t.getAttribute('data-document-topics') &&
        t.getAttribute('data-document-topics') !== ''
          ? t.getAttribute('data-document-topics')
          : this.tracking.topics.join(' | ')

      /* eslint-disable quote-props */
      const layer: GtmDownload = {
        event: 'download',
        eventCategory: 'download',
        eventAction: 'click',
        eventLabel: e.target.href,
        content: {
          title,
          'content-topic': topics,
          'content-type': type,
          'content-public': 1,
          'content-lead': 0,
        },
      }
      /* eslint-enable quote-props */

      // DGTM 5.2.2
      push(layer)
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })
    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else {
      if (document.body.dataset.rendering === 'SPA') {
        this.setResource(this.$router.$resource)
      }
      if (!contentTemplates.includes(this.tracking.template)) {
        gtmEvent(this.tracking.template)
      }
    }
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)

      // DGTM 5.1.1
      if (!contentTemplates.includes(this.tracking.template)) {
        gtmEvent(this.tracking.template)
      }
    },
  },
  head() {
    return this.meta
  },
})
