























import { defineComponent, computed, PropType } from '@vue/composition-api'
import { EmbedItem } from '@/inc/types'

export default defineComponent({
  props: {
    content: {
      type: Array as PropType<EmbedItem[]>,
      required: true,
    },
  },

  setup(props) {
    const htmlEmbed = computed(() =>
      props.content.filter(embed => embed.type === 'html')
    )

    return {
      htmlEmbed,
    }
  },
})
