import hash from 'object-hash'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import {
  LinkPropertyHref,
  ScriptPropertySrc,
  ScriptPropertyText,
} from 'vue-meta'

import { Resource, ResourceState, RootState, EmbedItem } from '@/inc/types'

const state: ResourceState = {
  content: {},
  meta: {},
  template: '',
  tracking: {},
}

const loadedInlineScripts = new Set<string>()

const getters: GetterTree<ResourceState, RootState> = {
  content: state => state.content,
  meta: state => state.meta,
  template: state => state.template,
  tracking: state => state.tracking,
}

const mutations: MutationTree<ResourceState> = {
  SET_RESOURCE(state, payload: Resource) {
    if (payload) {
      const { content, meta, template, tracking } = payload

      // Check if content has embed
      if (content.flexibleContent?.some(flex => flex.component === 'embeds')) {
        if (!meta.script) {
          meta.script = []
        }
        if (!meta.link) {
          meta.link = []
        }

        content.flexibleContent
          .filter(flex => flex.component === 'embeds')
          .forEach((flex: { content: EmbedItem[] }) => {
            flex.content
              .filter(item => item.type !== 'html')
              .forEach(item => {
                switch (item.type) {
                  case 'script':
                    // eslint-disable-next-line no-case-declarations
                    const scriptItem = {
                      vmid: hash(item),
                      ...item.attributes,
                    } as ScriptPropertySrc

                    if (!loadedInlineScripts.has(scriptItem.vmid as string)) {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      meta.script!.push(scriptItem)
                    }

                    loadedInlineScripts.add(scriptItem.vmid as string)

                    break
                  case 'scriptInline':
                    // eslint-disable-next-line no-case-declarations
                    const inlineItem = {
                      vmid: hash(item),
                      innerHTML: `setTimeout(() => {
                                    ${item.content}
                                  }, 1000)`,
                    } as ScriptPropertyText

                    if (!meta.__dangerouslyDisableSanitizersByTagID) {
                      // eslint-disable-next-line id-length
                      meta.__dangerouslyDisableSanitizersByTagID = {}
                    }

                    meta.__dangerouslyDisableSanitizersByTagID[
                      inlineItem.vmid as string
                    ] = ['innerHTML']

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    meta.script!.push(inlineItem)
                    break
                  case 'link':
                    console.log('LINK', item)
                    // eslint-disable-next-line no-case-declarations
                    const linkItem = {
                      ...item.attributes,
                    } as LinkPropertyHref

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    meta.link!.push(linkItem)
                    break

                  default:
                    break
                }
              })
          })
      }

      state.content = content
      state.meta = meta
      state.template = template
      state.tracking = tracking
    }
  },
}

const actions: ActionTree<ResourceState, RootState> = {}

export const resource: Module<ResourceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
