
























import { defineComponent } from '@vue/composition-api'
import { push, GtmShare } from '@/inc/utils'

export default defineComponent({
  name: 'g-share',
  props: {
    label: {
      type: String,
      default: () => 'Share this',
    },
    light: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(_props, ctx) {
    const items = ['twitter', 'facebook', 'linkedin']
    const openPopup = (url, title, width, height) => {
      const left = (window.screen.width - width) / 2
      const top = (window.screen.height - height) / 2

      return window.open(
        url,
        title,
        `status=no,resizable=yes,height=${height},width=${width},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no` // eslint-disable-line max-len
      )
    }

    const open = slug => {
      const { href } = window.location
      let url

      switch (slug) {
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?u=${href}`
          openPopup(url, 'Share on Facebook', 575, 300)
          break
        case 'linkedin':
          url = `https://www.linkedin.com/shareArticle?mini=true&url=${href}`
          openPopup(url, 'Share on Linkedin', 550, 255)
          break
        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${href}`
          openPopup(url, 'Share on Twitter', 550, 255)
          break
        default:
      }

      if (ctx.root.$router.$$type === 'client') {
        /* eslint-disable quote-props */
        const layer: GtmShare = {
          event: 'social-share',
          eventCategory: 'social',
          eventAction: 'share',
          eventLabel: slug,
          'shared-url': href,
        }
        /* eslint-enable quote-props */

        // DGTM 5.2.4
        push(layer)
      }
    }

    return {
      items,
      open,
      openPopup,
    }
  },
})
